import React from 'react';
import { useRouter } from 'next/router';
import Image from 'next/legacy/image';
import Link from 'next/link';

import styles from './404.module.scss';
import Logo from '@assets/icons/logo.svg';
import NotFoundGif from '@assets/extends/notfoundgf.gif';
import store from '@utils/store';

const Custom404 = () => {
  const router = useRouter();

  const loggedIn = store.get('user');

  const goToPage = (route: string) => {
    router.push(route);
  };
  const goBack = () => {
    router.back();
  };

  return (
    <div className={styles.notFoundContainer}>
      <div className={styles.header}>
        <div className={styles.logoWrapper}>
          <Link href="/dashboard">
            <Logo
              className="mx-auto cursor-pointer"
              width="45"
              height="45"
              viewBox="0 0 32 32"
            />
          </Link>
        </div>
        <div className={styles.menu}>
          <ul>
            <li>
              <a href="https://popupsmart.com/explore-features/">Features</a>
            </li>
            <li>
              <a href="https://popupsmart.com/pricing/">Pricing</a>
            </li>
            {loggedIn ? (
              <li>
                <button type="button" onClick={() => goToPage('/')}>
                  Dashboard
                </button>
              </li>
            ) : (
              <React.Fragment>
                <li>
                  <button type="button" onClick={() => goToPage('/login')}>
                    Sign in
                  </button>
                </li>
                <li>
                  <button type="button" onClick={() => goToPage('/register')}>
                    Register
                  </button>
                </li>
              </React.Fragment>
            )}
          </ul>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.imageWrapper}>
          <Image src={NotFoundGif} alt="Not found" width={220} height={213} />
        </div>
        <div className={styles.title}>
          The page you were looking for doesn’t exist
        </div>
        <div className={styles.buttons}>
          <div className={styles.buttonWrapper}>
            <button
              className="px-4 py-2 rounded-md hover:text-gray-900"
              onClick={() => goBack()}
            >
              Go Back
            </button>
          </div>

          <button
            className="px-4 py-2 rounded-md bg-bluesmart text-white"
            onClick={() => goToPage('/')}
          >
            Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default Custom404;
